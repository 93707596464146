import Generator from "pptxgenjs";

/**
 * Generates a powerpoint with a dedicated slide for each image in `images`
 * Downloads the result as `fileName` automatically
 */
export const generateDeck = async (fileName: string, images: File[]) => {
  // take the files given by the user and convert them to the expected format
  const dataUrls = await Promise.all(
    images.map((img) => {
      // we use a promise to represent this work because it can be slow
      // this way we can let all the conversions happen at once
      // using Promise.all to continue when they've all completed
      return new Promise<string>((resolve, reject) => {
        // create a file reader
        const reader = new FileReader();

        // if the reader reports an error, fail that conversion
        reader.addEventListener("error", () => {
          reject("Failed to read file");
        });
        // if the reader loads the file, succeed with the result
        reader.addEventListener("loadend", () => {
          resolve(reader.result as string);
        });

        // finally, start the reader, passing along a single image
        reader.readAsDataURL(img);
      });
    })
  );

  // create the powerpoint generator
  const gen = new Generator();

  // process each image
  dataUrls.forEach((url) => {
    // create a dedicated slide
    let slide = gen.addSlide();
    // add the image to the slide, filling it
    slide.addImage({
      data: url,
      w: "100%",
      h: "100%",
    });
  });

  // save the generated file, downloading it automatically for the user
  return gen.writeFile({ fileName });
};
