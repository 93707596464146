import {
  Box,
  BoxExtendedProps,
  Button,
  FileInput,
  Form,
  FormField,
  Grommet,
  Heading,
  TextInput,
} from "grommet";
import { useState } from "react";
import { generateDeck } from "./generator";

/**
 * A small component for drawing a header across the page
 * It will place any passed `children` inside of it
 */
const AppBar = (props: BoxExtendedProps) => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="brand"
    pad={{ left: "medium", right: "small", vertical: "small" }}
    elevation="medium"
    style={{ zIndex: "1" }}
    {...props}
  />
);

/**
 * The main application component
 */
export const App = () => {
  // flag used to determine if a deck is currently being generated
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  return (
    <Grommet full>
      <Box fill>
        <AppBar>
          <Heading level="3" margin="none">
            Image Deck
          </Heading>
        </AppBar>
        <Box margin="auto" width="medium">
          <Form
            validate="change"
            onSubmit={(ev) => {
              // type the value object, to make our lives easier below
              type Value = { fileName: string; images: File[] };
              const { fileName, images } = ev.value as Value;

              // mark that we're generating now
              setIsGenerating(true);

              // generate a deck from the form data, downloading the deck automatically
              // then reset the form
              generateDeck(fileName, images)
                .then(() => {
                  const el = ev.target as HTMLFormElement;

                  // TODO(bengreenier): is this bad ux?
                  el.reset();
                })
                .catch((err) => {
                  // catch any unknown errors that may occur, and log them
                  console.error(err);
                })
                .then(() => {
                  // mark that we're done generating
                  setIsGenerating(false);
                });
            }}
          >
            <FormField label="Images" name="images" required>
              <FileInput name="images" multiple={true} accept=".png,.jpg" />
            </FormField>
            <FormField
              label="File name"
              name="fileName"
              required
              validate={(fileName: string) => {
                // we enforce the filename requirements
                // we don't need to enforce that it's non-empty, we get that for free
                if (!fileName.endsWith(".pptx")) {
                  return "must end with .pptx";
                }

                // this means "valid"
                return undefined;
              }}
            >
              <TextInput name="fileName" placeholder="your_deck.pptx" />
            </FormField>
            <Box direction="row" justify="between" margin={{ top: "medium" }}>
              <Button type="reset" label="Reset" disabled={isGenerating} />
              <Button
                type="submit"
                label="Generate Deck"
                primary
                disabled={isGenerating}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </Grommet>
  );
};
